@mixin lg {
	@include r(1900) {
		@content; } }

@mixin md {
	@include r(1279) {
		@content; } }

@mixin sm {
	@include r(1023) {
		@content; } }

@mixin xs {
	@include r(767) {
		@content; } }

@mixin mob-fix {
	@media only screen and (max-width: 767px) and (orientation: landscape) {
		@content; } }

@mixin trs {
	transition: all 0.25s ease-out; }

// color variables
$black: #000;
$white: #fff;
$yellow: #ffcc00;
$blue: #0094f8;
$gray: #191919;
