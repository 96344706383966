@charset "UTF-8";
/* LIBS */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
a, area, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Reset
   ========================================================================== */
h1, h2, h3, h4, h5, h6, p, blockquote, em, img, i, dl, dt, ol, ul, li, video, canvas {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

/** to remove the rounded in IOS*/
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
}

a:hover, a:focus {
  text-decoration: none;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  vertical-align: top;
}

img {
  max-width: 100%;
  vertical-align: top;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

::-ms-clear,
::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: transparent;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: bold;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: #ffffff;
}

.highcharts-plot-border, .highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

/* Titles */
.highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}

.highcharts-subtitle {
  fill: #666666;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: #666666;
}

.highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}

.highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-tick {
  stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}

.highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}

.highcharts-tooltip text {
  fill: #333333;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-state-hover path {
  transition: stroke-width 50;
  /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}

.highcharts-color-1 {
  fill: #434348;
  stroke: #434348;
}

.highcharts-color-2 {
  fill: #90ed7d;
  stroke: #90ed7d;
}

.highcharts-color-3 {
  fill: #f7a35c;
  stroke: #f7a35c;
}

.highcharts-color-4 {
  fill: #8085e9;
  stroke: #8085e9;
}

.highcharts-color-5 {
  fill: #f15c80;
  stroke: #f15c80;
}

.highcharts-color-6 {
  fill: #e4d354;
  stroke: #e4d354;
}

.highcharts-color-7 {
  fill: #2b908f;
  stroke: #2b908f;
}

.highcharts-color-8 {
  fill: #f45b5b;
  stroke: #f45b5b;
}

.highcharts-color-9 {
  fill: #91e8e1;
  stroke: #91e8e1;
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
  fill: #333333;
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}

.highcharts-column-series rect.highcharts-point {
  stroke: #ffffff;
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item text {
  fill: #333333;
  font-weight: bold;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: #000000;
}

.highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms;
}

.highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: #cccccc;
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: #ffffff;
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: #6685c2;
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}

.highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: #666666;
}

.highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}

.highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: #333333;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #333333;
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #335cad;
}

.highcharts-button-disabled text {
  fill: #cccccc;
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: #666666;
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: #333333;
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}

.highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: #999999;
}

.highcharts-null-point {
  fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: #ffffff;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}

.highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff;
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: #e6e6e6;
}

body.fancybox-active {
  overflow: hidden;
}

body.fancybox-iosfix {
  position: fixed;
  left: 0;
  right: 0;
}

.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99992;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-caption-wrap, .fancybox-infobar, .fancybox-toolbar {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s,visibility 0s linear .25s;
  box-sizing: border-box;
}

.fancybox-show-caption .fancybox-caption-wrap, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s,visibility 0s;
}

.fancybox-infobar {
  top: 0;
  left: 0;
  font-size: 13px;
  padding: 0 10px;
  height: 44px;
  min-width: 44px;
  line-height: 44px;
  color: #ccc;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
  mix-blend-mode: exclusion;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translateZ(0);
}

.fancybox-is-closing .fancybox-stage {
  overflow: visible;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.fancybox-slide:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--image {
  overflow: visible;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--video .fancybox-content, .fancybox-slide--video iframe {
  background: #000;
}

.fancybox-slide--map .fancybox-content, .fancybox-slide--map iframe {
  background: #e5e3df;
}

.fancybox-slide--next {
  z-index: 99995;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide > base, .fancybox-slide > link, .fancybox-slide > meta, .fancybox-slide > script, .fancybox-slide > style, .fancybox-slide > title {
  display: none;
}

.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: zoom-in;
}

.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-image, .fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff;
}

.fancybox-iframe {
  display: block;
  padding: 0;
  border: 0;
  height: 100%;
}

.fancybox-error, .fancybox-iframe {
  margin: 0;
  width: 100%;
  background: #fff;
}

.fancybox-error {
  padding: 40px;
  max-width: 380px;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 16px;
  line-height: 20px;
}

.fancybox-button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 10px;
  border: 0;
  border-radius: 0;
  background: rgba(30, 30, 30, 0.6);
  transition: color .3s ease;
  cursor: pointer;
  outline: none;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:focus, .fancybox-button:hover {
  color: #fff;
}

.fancybox-button[disabled] {
  color: #ccc;
  cursor: default;
  opacity: .6;
}

.fancybox-button svg {
  display: block;
  position: relative;
  overflow: visible;
  shape-rendering: geometricPrecision;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3;
}

.fancybox-button--share svg path {
  stroke-width: 1;
}

.fancybox-button--pause svg path:nth-child(1), .fancybox-button--play svg path:nth-child(2) {
  display: none;
}

.fancybox-button--zoom svg path {
  fill: transparent;
}

.fancybox-navigation {
  display: none;
}

.fancybox-show-nav .fancybox-navigation {
  display: block;
}

.fancybox-navigation button {
  position: absolute;
  top: 50%;
  margin: -50px 0 0;
  z-index: 99997;
  background: transparent;
  width: 60px;
  height: 100px;
  padding: 17px;
}

.fancybox-navigation button:before {
  content: "";
  position: absolute;
  top: 30px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 0;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.fancybox-close-small:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 22px/30px Arial,Helvetica Neue,Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  transition: background-color .25s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus {
  outline: none;
}

.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}

.fancybox-slide--iframe .fancybox-close-small, .fancybox-slide--image .fancybox-close-small {
  top: 0;
  right: -40px;
}

.fancybox-slide--iframe .fancybox-close-small:after, .fancybox-slide--image .fancybox-close-small:after {
  font-size: 35px;
  color: #aaa;
}

.fancybox-slide--iframe .fancybox-close-small:hover:after, .fancybox-slide--image .fancybox-close-small:hover:after {
  color: #fff;
  background: transparent;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none;
}

.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 2vw 0;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8));
  pointer-events: none;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a, .fancybox-caption button, .fancybox-caption select {
  pointer-events: all;
  position: relative;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

.fancybox-slide > .fancybox-loading {
  border: 6px solid rgba(99, 99, 99, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
}

.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  opacity: 0;
}

.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}

.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0;
}

.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  opacity: 1;
}

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}

.fancybox-share {
  padding: 30px;
  border-radius: 3px;
  background: #f4f4f4;
  max-width: 90%;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px;
  font-size: 35px;
  font-weight: 700;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

p.fancybox-share__links {
  margin-right: -10px;
}

.fancybox-share__button {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px 10px 0;
  padding: 0 15px;
  min-width: 130px;
  border: 0;
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  transition: all .2s;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  position: relative;
  top: -1px;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  vertical-align: middle;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 0;
  padding: 10px 15px;
  background: transparent;
  color: #5d5b5b;
  font-size: 14px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #d7d7d7;
}

.fancybox-thumbs {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 212px;
  margin: 0;
  padding: 2px 2px 4px;
  background: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
  white-space: nowrap;
}

.fancybox-thumbs-x > ul {
  overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 2px;
  width: 100px;
  height: 75px;
  max-width: calc(50% - 4px);
  max-height: calc(100% - 8px);
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px);
  }
}

/* PARTIALS */
@font-face {
  font-family: "OpenSans-Regular";
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-SemiBold.woff") format("woff"), url("../fonts/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AmazonEmber-Regular";
  src: url("../fonts/AmazonEmber-Regular.eot");
  src: url("../fonts/AmazonEmber-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AmazonEmber-Regular.woff") format("woff"), url("../fonts/AmazonEmber-Regular.woff2") format("woff2"), url("../fonts/AmazonEmber-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AmazonEmber-Bold";
  src: url("../fonts/AmazonEmber-Bold.eot");
  src: url("../fonts/AmazonEmber-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AmazonEmber-Bold.woff") format("woff"), url("../fonts/AmazonEmber-Bold.woff2") format("woff2"), url("../fonts/AmazonEmber-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.icon-circle {
  width: 1em;
  height: 1em;
  fill: #9fbbd7;
  stroke: #e8f4fe;
}

.icon-screen-tooltip-bg {
  width: 2.26em;
  height: 1em;
  fill: #dee2ef;
  stroke: initial;
}

.icon-squares {
  width: 1.09em;
  height: 1em;
  fill: #000414;
  stroke: initial;
}

@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* COMPONENTS */
body {
  min-width: 320px;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #191919;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(25, 25, 25, 0.44);
  z-index: 330;
  opacity: 0;
  transition: .3s ease;
}

body.is-hidden::after {
  opacity: 1;
}

.out {
  overflow: hidden;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 10px;
  }
}

.btn {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #0094f8;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  transition: all 0.25s ease-out;
}

.btn_transp {
  padding: 8px 22px;
  background: transparent;
  color: #0094f8;
}

.no-touch .btn_transp:hover {
  background: #0094f8;
  color: #fff;
}

.btn_blue {
  padding: 9px 27px;
  background-color: #0094f8;
  color: #fff;
}

.no-touch .btn_blue:hover {
  background: #006fbb;
  border-color: #006fbb;
}

.btn_border {
  position: relative;
  height: 55px;
  padding: 15px 30px;
  border: 1px solid #0094f8;
  background-color: #0094f8;
  line-height: 21px;
  font-size: 24px;
  color: #fff;
}

.no-touch .btn_border:hover::before {
  background: #0076c5;
}

.btn_border::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #fff;
  content: '';
}

.btn_border::before {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #0094f8;
  content: '';
  transition: all 0.25s ease-out;
}

.btn_border span {
  position: relative;
  z-index: 2;
}

.title-xs {
  font-family: "OpenSans-SemiBold", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  color: #fff;
}

.input {
  width: 100%;
  height: 43px;
  padding: 0 14px;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #000;
  border: 1px solid #d1d1d1;
  background-color: #fafafa;
}

.input:-moz-placeholder, .input::-moz-placeholder {
  color: #acacac;
  opacity: 1;
}

.input::-webkit-input-placeholder {
  color: #acacac;
}

.check-box {
  position: relative;
}

.check-box:hover span {
  color: #0094f8;
}

.check-box label {
  display: block;
  cursor: pointer;
  font-size: 0;
  text-align: left;
}

.check-box label span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 14px);
  padding-left: 9px;
  font-size: 14px;
  user-select: none;
  transition: all 0.25s ease-out;
}

.check-box__square {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border: 1px solid #d1d1d1;
  background: #fff;
  font-size: 0;
}

.check-box__square::after {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #0094f8;
  content: '';
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.25s ease-out;
}

.check-box input {
  display: none;
}

.check-box input:checked + label .check-box__square::after {
  opacity: 1;
  transform: scale(1);
}

.support-btn {
  position: fixed;
  right: 48px;
  bottom: 60px;
  z-index: 1;
  width: 100px;
  height: 100px;
  border: 8px solid #fff;
  border-radius: 50%;
  background: rgba(0, 148, 248, 0.11);
  transition: all 0.25s ease-out;
}

.no-touch .support-btn:hover {
  background: #95cdfc;
}

.support-btn::after {
  position: absolute;
  z-index: -1;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 1px solid rgba(0, 148, 248, 0.32);
  border-radius: 50%;
  content: '';
}

.support-btn__dots {
  position: absolute;
  top: 54%;
  left: 40px;
  display: flex;
  justify-content: space-between;
  width: 17px;
}

.support-btn__dots-circle {
  width: 3px;
  height: 3px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}

.arrow-link {
  color: #0094f8;
  line-height: 1;
}

.no-touch .arrow-link:hover .arrow-link__icon {
  transform: translateX(5px);
}

.arrow-link span {
  margin-right: 4px;
}

.arrow-link__icon {
  vertical-align: 1px;
  transition: .3s ease;
}

.icon-plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  opacity: 0;
  transition: opacity .3s ease;
}

.icon-plus::after {
  height: 1px;
  width: 100%;
  top: 50%;
  margin-top: -1px;
  left: 0;
}

.icon-plus::before {
  height: 100%;
  width: 1px;
  left: 50%;
}

.icon-plus::after, .icon-plus::before {
  transform: translate3d(0, 0, 0);
  position: absolute;
  background: #fff;
  content: '';
  z-index: 2;
}

.icon-plus_close {
  opacity: 1;
  width: 15px;
  height: 15px;
}

.icon-plus_close::after {
  top: 50%;
  left: 0;
  margin-top: -1px;
  transform: rotate(-45deg);
}

.icon-plus_close::before {
  transform: rotate(45deg);
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
}

.icon-plus_sm {
  width: 11px;
  height: 11px;
  opacity: 1;
}

.icon-plus_sm::before {
  left: 5px;
}

.icon-plus_lg {
  height: 34px;
  width: 34px;
  z-index: 1;
}

.icon-plus_lg::before, .icon-plus_lg::after {
  background: rgba(255, 255, 255, 0.5);
}

.slider-arr {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  z-index: 1;
}

.slider-arr:hover svg {
  fill: #0094f8;
}

.slider-arr_prev {
  left: -30px;
}

.slider-arr_next {
  right: -30px;
}

.slider-arr svg {
  fill: #000;
  transition: all 0.25s ease-out;
}

.slider-arr.slick-disabled svg {
  fill: #000;
}

.pulse-anim {
  background-color: #0094f8;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  opacity: .75;
}

.pulse-anim:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  top: 0;
  background-color: #0094f8;
  border-radius: 100%;
  -webkit-animation: pulse 2s infinite ease-in-out;
  animation: pulse 2s infinite ease-in-out;
}

.pulse-anim:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  top: 0;
  background-color: #0094f8;
  border-radius: 100%;
  -webkit-animation: pulse 2s infinite ease-in-out;
  animation: pulse 2s infinite ease-in-out;
  margin: auto 0 auto -32.5px;
  height: 65px;
  width: 65px;
  opacity: .5;
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.pulse-anim:after {
  margin: auto 0 auto -46px;
  height: 92px;
  width: 92px;
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
  opacity: .25;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 310;
  border-top: 4px solid #0094f8;
  background: #fff;
  box-shadow: 0 1px 0 #dee2ef;
}

.header.is-overlapped {
  z-index: 300;
}

.header__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 15px 13px;
}

.header__bottom {
  padding: 20px 0;
  box-shadow: 0 -1px 0 #dee2ef;
  background: #fff;
}

.header__bottom-container {
  display: flex;
  align-items: center;
}

.header__nav {
  margin: 0 22px 0 auto;
}

.header__login {
  display: flex;
  align-items: center;
  color: #0094f8;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  transition: .35s ease;
}

.header__login:hover {
  color: #004879;
}

.header__login span {
  margin-left: 8px;
}

.header__lang .lang__list {
  margin-top: 5px;
}

.lang {
  position: relative;
  display: flex;
  align-items: center;
}

.lang__list {
  position: relative;
  transition: all 0.25s ease-out;
}

.lang__list.is-active .lang__list-items {
  opacity: 1;
  visibility: visible;
}

.lang__list-items {
  position: absolute;
  right: 0;
  top: 41px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  width: 190px;
  border: 1px solid #dee2ef;
  transition: all 0.25s ease-out;
}

.lang__list-items li a {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
}

.no-touch .lang__list-items li a:hover {
  background: #e5e5e5;
}

.lang__list.is-active .lang__link::after {
  transform: rotate(180deg);
}

.lang__list-icon {
  width: 24px;
  height: 18px;
  margin-right: 10px;
}

.lang__list-icon svg, .lang__list-icon img {
  width: 100%;
  height: 100%;
}

.lang__list-text {
  font-size: 14px;
  color: #747474;
}

.lang__link {
  position: relative;
  display: block;
  width: 42px;
}

.lang__link::after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 3.5px 0 3.5px;
  border-color: #747474 transparent transparent transparent;
  content: '';
  transition: all 0.25s ease-out;
}

.lang__translate {
  min-width: 174px;
  margin-right: 13px;
  border: 1px solid #dee2ef;
}

.lang__translate .goog-te-gadget-simple {
  padding: 6px 7px;
  height: 38px;
  border: 0;
}

.lang__translate .goog-te-gadget-icon {
  width: 18px;
}

.lang__translate .goog-te-gadget-simple .goog-te-menu-value span:nth-child(3) {
  display: none;
}

.lang__translate .goog-te-gadget-simple .goog-te-menu-value span[aria-hidden] {
  position: relative;
  top: -4px;
  left: -1px;
  font-size: 0;
}

.lang__translate .goog-te-gadget-simple .goog-te-menu-value span[aria-hidden]::after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 3.5px 0 3.5px;
  border-color: #747474 transparent transparent transparent;
  content: '';
}

.lang__translate .goog-te-gadget-simple .goog-te-menu-value span {
  vertical-align: -1px;
  color: #737373 !important;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
}

.lang__translate .goog-te-menu-value {
  margin-left: 6px;
}

.lang__translate .goog-te-gadget-icon {
  margin-left: 4px;
}

.logo {
  display: flex;
  align-items: center;
}

.no-touch .logo:hover p,
.no-touch .logo:hover span {
  color: #0094f8;
}

.logo__icon {
  margin-right: 13px;
}

.logo p {
  color: #000;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  text-transform: uppercase;
  transition: all 0.25s ease-out;
}

.logo span {
  text-transform: none;
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  transition: all 0.25s ease-out;
}

.nav:hover li.is-active a {
  color: #747474;
}

.nav:hover li.is-active::after {
  background-color: transparent;
}

.nav__list {
  display: flex;
}

.nav__list li {
  position: relative;
}

.nav__list li::after {
  position: absolute;
  top: -21px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1px;
  background: transparent;
  content: '';
  transition: .3s ease;
}

.nav__list li.is-active::after {
  background: #0094f8;
}

.nav__list li.is-active a {
  color: #0094f8;
  pointer-events: none;
}

.nav__list li:hover::after {
  background: #0094f8;
}

.nav__list a {
  display: block;
  padding: 0 12px;
  color: #747474;
  transition: .3s ease;
}

.no-touch .nav__list a:hover {
  color: #0094f8;
}

.goog-te-menu-frame {
  box-shadow: none !important;
}

.footer {
  position: relative;
  background-color: #2a363d;
  color: #9f9f9f;
}

.footer__container {
  padding: 33px 15px 40px;
}

.footer__logo {
  margin-bottom: 25px;
}

.footer__logo .logo__icon {
  margin-right: 10px;
}

.footer__logo p {
  font-family: "OpenSans-SemiBold", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
}

.footer__logo p, .footer__logo span {
  color: #fff;
}

.footer__row {
  position: relative;
  margin: 0 -14px;
}

.footer__row:after {
  content: "";
  display: table;
  clear: both;
}

.footer__col {
  float: left;
  width: calc(33.33333% - 28px);
  margin: 0 14px;
  border-right: 1px solid #3b4b55;
}

.footer__col::before, .footer__col::after {
  position: absolute;
  top: 4px;
  z-index: 1;
  width: 1px;
  height: 37px;
  background: #fff;
  content: '';
}

.footer__col::before {
  top: 0;
}

.footer__col::after {
  top: auto;
  bottom: 0;
}

.footer__col:nth-child(1)::before, .footer__col:nth-child(1)::after {
  left: 374px;
}

.footer__col:nth-child(2)::before, .footer__col:nth-child(2)::after {
  left: 763px;
}

.footer__col:nth-child(3) {
  border-right: 0;
  background: url("../img/bg/pointer.svg") no-repeat 10% 25%;
}

.footer__col:nth-child(3)::after, .footer__col:nth-child(3)::before {
  display: none;
}

.footer__article {
  margin-bottom: 22px;
}

.footer__title {
  margin-bottom: 25px;
}

.footer__bottom {
  box-shadow: 0 -1px 0 #3b4b55;
  background-color: #252f35;
}

.footer__bottom-container {
  display: flex;
  justify-content: space-between;
  padding: 22px 15px;
  line-height: 1;
}

.footer__list a {
  transition: all 0.25s ease-out;
}

.no-touch .footer__list a:hover {
  color: #0094f8;
}

.footer__subscr {
  margin-top: 20px;
}

.footer__lang .lang__link::after {
  border-color: #fff transparent transparent transparent;
}

.footer__lang .lang__list-items {
  top: auto;
  bottom: 42px;
  border-color: #3b4b55;
  background: #2e393f;
}

.no-touch .footer__lang .lang__list-items a:hover {
  background: #0094f8;
}

.footer__lang .lang__list-text {
  color: #fff;
}

.subscr__title {
  margin-bottom: 30px;
}

.subscr__field {
  display: flex;
}

.subscr__input {
  height: 37px;
  background-color: #ffffff;
  border: 0;
}

.subscr__submit {
  height: 37px;
  width: 117px;
  flex: 0 0 117px;
  padding: 0 10px;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: 305;
  overflow: auto;
  outline: 0;
  text-align: center;
  font-size: 0;
  transform: translate3d(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease, transform .5s ease, visibility .5s;
  will-change: opacity, transform;
}

.popup:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

.popup.opened {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.popup__close {
  position: absolute;
  top: 0;
  right: -31px;
  width: 46px;
  height: 47px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #064b7a;
  transition: all 0.25s ease-out;
}

.popup__close:hover {
  background: #0a78c3;
}

.popup__close-icon {
  position: relative;
  width: 20px;
  height: 20px;
}

.popup__close-icon .icon-plus {
  position: static;
  opacity: 1;
}

.popup__container {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 748px;
  padding: 0 15px;
  position: relative;
  margin: 30px auto;
  z-index: 102;
  transition: opacity .5s ease, transform .5s ease;
  transform: translate3d(0, -100px, 0);
  will-change: transform, opacity;
}

@media only screen and (max-width: 767px) {
  .popup__container {
    padding: 30px 15px;
  }
}

.popup__container_top {
  vertical-align: top;
  max-width: 740px;
  margin: 30px auto;
}

body.popup-opened {
  overflow: hidden;
}

.popup.opened .popup__container {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.popup.opened[data-popup-id='login-popup'] {
  background: rgba(14, 28, 42, 0.87);
  z-index: 350;
}

/* PAGES */
.screen {
  position: relative;
  min-height: 623px;
  margin-top: 135px;
  background: url("../img/bg/screen_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media screen and (min-width: 1921px) {
  .screen {
    background: url("../img/bg/screen_bg.jpg") no-repeat 50% 50%/contain, url("../img/bg/screen_bg.jpg") repeat-x 0 50%/contain;
  }
}

.screen__container {
  padding: 90px 15px;
}

.screen__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 28px 116px 28px 24px;
  border-left: 1px solid #fff;
}

.screen__text {
  max-width: 786px;
  padding-right: 20px;
}

.screen__title {
  max-width: 560px;
  margin-bottom: 20px;
  color: #0094f8;
  font-size: 30px;
  line-height: 35px;
}

.screen__descr {
  margin-bottom: 25px;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.screen__action {
  position: relative;
  margin-top: -8px;
}

.screen__action-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 172px;
  border-radius: 50%;
  border: 13px solid rgba(255, 255, 255, 0.11);
  background: rgba(255, 255, 255, 0.1);
}

.no-touch .screen__action-btn:hover::after {
  background: rgba(255, 255, 255, 0.6);
}

.no-touch .screen__action-btn:hover .screen__action-icon use {
  fill: #0094f8;
}

.screen__action-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgba(255, 255, 255, 0.55);
  content: '';
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.25s ease-out;
}

.screen__action-icon {
  position: relative;
  z-index: 2;
}

.screen__action-icon use {
  transition: all 0.25s ease-out;
}

.screen__action-tooltip {
  position: absolute;
  top: -66px;
  left: 90%;
  padding: 0 20px;
  height: 31px;
  line-height: 30px;
  white-space: nowrap;
  background: #0094f8;
  text-align: center;
}

.screen__action-tooltip p {
  font-size: 21px;
  color: #fff;
}

.screen__action-tooltip-icon {
  position: absolute;
  top: -8px;
  left: 0;
}

.chart {
  position: relative;
  max-width: 1140px;
  margin: -180px auto 0;
  background: #fff;
  border: 1px solid #d8d8d8;
}

.chart_entry-page {
  margin: -172px auto 0;
}

.chart__inner:after {
  content: "";
  display: table;
  clear: both;
}

.chart__block {
  float: left;
  width: calc(100% - 387px);
  padding: 19px 29px 19px 19px;
}

.chart__tezis {
  display: none;
  position: relative;
  float: left;
  width: 367px;
  margin: 19px 0 19px 0;
}

.chart__tezis.slick-initialized {
  display: block;
}

.chart__tezis-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
}

.chart__tezis-dots {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 15px;
  font-size: 0;
}

.chart__tezis-dots li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
}

.chart__tezis-dots li.slick-active button {
  background: #0094f8;
}

.chart__tezis-dots button {
  width: 25px;
  height: 1px;
  background: #fff;
  transition: .5s ease;
}

.chart__tezis-item {
  position: relative;
  z-index: 1;
  height: 319px;
  padding: 45px 60px 60px;
  font-size: 0;
  text-align: center;
}

.chart__tezis-item:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

.chart__tezis-item p {
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-size: 24px;
  color: #fff;
}

.highcharts-container {
  transform: translate3d(0, 0, 0);
}

.highcharts-title {
  font-family: "OpenSans-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #191919;
}

.info {
  position: relative;
  padding: 75px 0 78px;
  background: url("../img/bg/info_bg-stroke.svg"), url("../img/bg/info_bg-fill.svg");
  background-position: 50% -37%;
  background-repeat: no-repeat;
  background-size: 1940px 400px;
}

@media screen and (min-width: 1921px) {
  .info {
    background-position: 50% 0;
    background-size: 2600px 400px;
  }
}

.info::after {
  pointer-events: none;
  position: absolute;
  z-index: 0;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 220px;
  background: #f7fcff;
  content: '';
}

@media screen and (min-width: 1921px) {
  .info::after {
    bottom: -91px;
  }
}

.info__container {
  position: relative;
  z-index: 1;
  display: flex;
}

.info__col:nth-child(1) {
  flex: 1 0 47.36842%;
  padding-right: 42px;
  border-right: 1px solid #c6c6c6;
}

.info__col:nth-child(2) {
  flex: 1 0 52.63158%;
  padding-left: 29px;
}

.info__title {
  margin-top: -4px;
  margin-bottom: 18px;
  max-width: 370px;
  font-size: 24px;
  line-height: 28px;
}

.info__title span {
  color: #0094f8;
}

.info__descr {
  margin-bottom: 30px;
}

.info__text {
  padding: 11px 21px;
  border: 1px dashed #aeaeae;
}

.info__text:nth-child(1n) {
  width: 504px;
  float: left;
}

.info__text:nth-child(2n) {
  width: 521px;
  float: right;
}

.info__text:not(:last-child) {
  margin-bottom: 22px;
}

.info__bg-bar {
  position: absolute;
}

.info__bg-bar_l {
  top: -43.5%;
  left: -25.1%;
}

@media screen and (min-width: 1921px) {
  .info__bg-bar_l {
    left: -45%;
  }
}

.info__bg-bar_r {
  top: -55.5%;
  right: -38.8%;
  height: 201px;
}

@media screen and (min-width: 1921px) {
  .info__bg-bar_r {
    right: -43.8%;
  }
}

.info__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: -1;
}

.info__bg_top {
  bottom: 40.3%;
  fill: #dceefd;
}

.info__bg_bottom {
  fill: #f7fcff;
  bottom: 39.2%;
}

.info__dot-bg {
  position: absolute;
}

.info__dot-bg_1 {
  top: 23.8%;
  left: -117px;
}

@media screen and (min-width: 1921px) {
  .info__dot-bg_1 {
    top: 34.8%;
    left: -367px;
  }
}

.info__dot-bg_2 {
  top: 48.6%;
  left: 538px;
}

.info__dot-bg_3 {
  top: -2.7%;
  right: -197px;
}

@media screen and (min-width: 1921px) {
  .info__dot-bg_3 {
    top: -1.7%;
    right: -450px;
  }
}

.system {
  padding: 50px 0;
  min-height: 736px;
}

.system__container {
  position: relative;
  font-size: 0;
  text-align: center;
}

.system__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.33333%;
  text-align: center;
}

.system__item.is-visible {
  z-index: 331;
}

.system__item.is-visible .system__item-title {
  color: #fff;
}

.system__item-title {
  position: relative;
  margin-bottom: 25px;
  color: #191919;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  transition: .5s ease;
}

.system__item-img-wrap {
  position: relative;
}

.system__item-img {
  position: relative;
  pointer-events: none;
}

.system__item_data .system__item-img {
  top: -6px;
  left: 19px;
}

.system__item_data .system__item-title {
  left: 12px;
}

.system__item_cloud {
  position: relative;
  cursor: pointer;
  min-height: 260px;
}

.system__item_cloud .system__item-img {
  position: absolute;
  min-width: 501px;
  top: 1px;
  left: -85px;
}

.system__item_cloud .system__item-title {
  left: -5px;
}

.system__item_portfolio .system__item-img {
  position: absolute;
  min-width: 428px;
  top: -5px;
  left: -65px;
}

.system__item_portfolio .system__item-title {
  left: -22px;
}

.system__item_internet {
  margin-top: 118px;
}

.system__item_internet .system__item-img {
  position: absolute;
  left: 35px;
}

.system__arrows {
  pointer-events: none;
  position: absolute;
  top: 28.7%;
  fill: #98acba;
  z-index: 1;
}

.system__arrows_1 {
  left: 32.7%;
}

.system__arrows_2 {
  left: 63.5%;
}

.system__arrows_3 {
  top: 74%;
  left: 48.2%;
  transform: rotate(90deg);
}

.is-hidden .system__arrows {
  z-index: 331;
}

.system__tip-zone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.system__tip-zone.is-active .system-popup {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
}

.system-popup {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 350;
  display: flex;
  align-items: flex-start;
  width: 684px;
  height: 300px;
  padding: 50px 0 0;
  margin-left: -342px;
  transform: translate3d(0, 100px, 0);
  transition: .5s ease;
  cursor: default;
}

.system-popup__item {
  text-align: center;
  cursor: pointer;
  z-index: 3;
  transform: scale(0.74) translate(55%, 0);
}

.no-touch .system-popup__item:hover .system-popup__tooltip,
.no-touch .system-popup__item:hover .icon-plus,
.no-touch .system-popup__item:hover .system-popup__item-img::before {
  opacity: 1;
}

.system-popup__item-img {
  display: block;
  position: relative;
  border: 10px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 228px;
  height: 228px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.system-popup__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.system-popup__item-img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100px;
  background: #fff;
  z-index: 1;
  content: '';
}

.system-popup__item-img::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 86, 114, 0.4);
  content: '';
  opacity: 0;
  transition: opacity .3s ease;
}

.system-popup__item_center {
  transform: scale(1) translate3d(0, 0, 0);
  cursor: default;
}

.system-popup__item_center .system-popup__tooltip {
  top: -46%;
  left: 70%;
  transform: none;
}

.system-popup__item_center {
  z-index: 3;
}

.system-popup__item_center + .system-popup__item {
  transform: scale(0.74) translate(-55%, 0);
  z-index: 2;
}

.system-popup__tooltip {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: -61%;
  left: 80%;
  width: 300px;
  z-index: 105;
  padding: 5px 0;
  font-size: 16px;
  text-align: left;
  color: #fff;
  transform: scale(1.35);
  transition: opacity .3s ease;
}

.system-popup__tooltip::after {
  position: absolute;
  top: 0;
  left: 7px;
  height: 1px;
  width: 97%;
  background: #fff;
  content: '';
}

.system-popup__tooltip-title {
  font-family: "OpenSans-SemiBold", Arial, sans-serif;
  font-weight: normal;
}

.system-popup__tooltip svg {
  position: absolute;
  top: 100%;
  left: -12px;
}

.tezis {
  padding: 145px 0 150px;
  background: url("../img/bg/map_bg.svg") 50% 50% no-repeat;
  border-top: 1px solid #ededed;
}

.tezis__row {
  display: flex;
  padding-left: 47px;
}

.tezis__row:not(:first-child) {
  padding-top: 72px;
}

.tezis__item {
  display: flex;
  align-items: center;
}

.tezis__item_right {
  flex: 0 1 500px;
  margin-left: auto;
}

.tezis__item_right .tezis__item-icon::after {
  width: 246px;
  height: 118px;
  top: 61px;
  right: 28px;
}

.tezis__item_center {
  flex: 0 1 560px;
  margin-left: auto;
  margin-right: auto;
}

.tezis__item_center .tezis__item-icon::after {
  width: 252px;
  height: 121px;
  top: 60px;
  right: 30px;
}

.tezis__item_left {
  flex: 0 1 550px;
  margin-right: auto;
}

.tezis__item_left .tezis__item-icon {
  position: relative;
  left: 2px;
}

.tezis__item_left .tezis__item-icon::after {
  display: none;
}

.tezis__item-inner {
  display: flex;
}

.tezis__item-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 68px;
  height: 66px;
  background: url("../img/icons/tezis-circle.svg") 50% 50%/contain no-repeat;
}

.tezis__item-icon::after {
  position: absolute;
  z-index: 1;
  background: url("../img/bg/tezis-dots.svg") 100% 100%/cover no-repeat;
  content: '';
}

.tezis__item-icon .icon-data {
  position: relative;
  top: 2px;
}

.tezis__item-text {
  padding-right: 30px;
  text-align: right;
}

.tezis__item-text p {
  color: #191919;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.tezis__item-text span {
  color: #0094f8;
}

.page {
  margin-top: 135px;
  padding: 80px 0 0;
  min-height: 374px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.page__title {
  color: #fff;
  font-size: 30px;
  line-height: 45px;
}

.schedule {
  padding: 87px 0 50px;
}

.schedule__container {
  position: relative;
  max-width: 1710px;
  padding: 0 40px;
}

.schedule__container::after {
  pointer-events: none;
  position: absolute;
  top: 4%;
  right: 10%;
  background: url("../img/bg/map_bg.svg") no-repeat 50% 50%/contain;
  height: 64.8%;
  width: 80.7%;
  content: '';
}

.schedule__container::before {
  position: absolute;
  bottom: 3.1%;
  left: 43.1%;
  opacity: .07;
  width: 27.8%;
  height: 19%;
  background: url("../img/bg/people_bg.png") no-repeat 50% 50%/contain;
  content: '';
}

.schedule__grid-bg {
  pointer-events: none;
  position: absolute;
  top: -20px;
  left: 15px;
  right: 68px;
  height: 100%;
  background: url("../img/bg/schedule-grid.svg") no-repeat 50% 50%/contain;
}

.schedule__inner {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
}

.schedule__row {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 100px;
}

.schedule__row:nth-child(2) {
  padding-right: 195px;
}

.schedule__row:nth-child(3) {
  justify-content: center;
  padding-right: 10px;
  padding-bottom: 105px;
}

.schedule__row:nth-child(4) {
  justify-content: flex-start;
  padding-left: 187px;
}

.schedule__row:nth-child(5) {
  justify-content: flex-start;
}

.schedule__row:nth-child(5) .schedule__item {
  flex: 0 0 334px;
}

.schedule__row:nth-child(5) .schedule__item::after {
  display: none;
}

.schedule__item {
  position: relative;
  flex: 0 0 350px;
  padding: 0 10px;
  text-align: right;
}

.schedule__item::after {
  position: absolute;
  bottom: -146px;
  right: 36px;
  width: 214px;
  opacity: .15;
  height: 134px;
  background: url("../img/bg/curved-line.svg") no-repeat 50% 50%;
  content: '';
}

.schedule__item-icon {
  position: relative;
  width: 55px;
  height: 55px;
  margin-right: 90px;
  margin-left: auto;
  border-radius: 50%;
  cursor: pointer;
  border: 7px solid transparent;
  transition: all 0.25s ease-out;
}

.schedule__item-icon::before {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #0094f8;
  z-index: 407;
  content: '';
  transition: all 0.25s ease-out;
}

.schedule__item-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: -1;
  background: #0094f8;
  content: '';
}

.schedule__item-icon:hover {
  border-color: rgba(204, 204, 204, 0.47);
}

.schedule__item-icon:hover + .schedule__item-tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.schedule__item-icon.is-active::before {
  opacity: 1;
}

.schedule__item-icon.is-active .pulse-anim {
  animation-play-state: paused;
}

.schedule__item-icon .icon-plus {
  z-index: 408;
}

.schedule__item-tooltip {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 17px);
  left: calc(50% - 168px);
  z-index: 403;
  width: 430px;
  padding: 15px 20px;
  background: #fff;
  border: 1px solid #cbcbcb;
  transition: .3s ease;
  text-align: left;
  transform: translateY(-20px);
}

.schedule__item-tooltip::before, .schedule__item-tooltip::after {
  position: absolute;
  left: 50%;
  margin-left: -5px;
  content: '';
}

.schedule__item-tooltip::after {
  bottom: -6px;
  margin-left: -5px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  content: '';
}

.schedule__item-tooltip::before {
  bottom: -7px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #cacaca transparent transparent transparent;
  content: '';
}

.schedule__item-tooltip p {
  line-height: 20px;
}

.schedule__item-text {
  margin-top: 5px;
  color: #001b30;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
}

.schedule__earn {
  position: absolute;
  right: 1px;
  bottom: 24.6%;
}

.schedule__earn-icon {
  position: absolute;
  z-index: 1;
  bottom: -80px;
  left: 50%;
  margin-left: -18px;
  animation: scaleAnim 3s ease infinite;
}

.schedule__earn-icon::after {
  position: absolute;
  top: 64px;
  left: -11px;
  width: 78px;
  height: 22px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAAAWCAYAAABud6qHAAAFF0lEQVRYR+2YyU4ySxiGqR7pCFGCaExMdOPCu/AmuB7C9XAT3oBbXbjRxMQoEjUMTfVQdfJ+p79OUcBPg3r+s7ATqKGrp6ffb2pR+912IiB2Our3oNrfAPfHa2qtN74WIVaeYvOBG89cfcFPghMMod/v03V6vV55Z4PBgOa63S7NXV9fi6urK4121e1jH88PBgPqdrtd3e/3y+W9Xo/WWGB/BOh3gCvPAVCABEAMptPplPubzSb1oyii9vHxkdqLi4va09MT9c/Pzxe4PTw80Pj09FTf399T/+zsrIQRxzH1x+MxtQXgcj/AAmgB89sg7gqOjrNBQT2sGEACoCAIBKAAyPPzM4193xee59E53t/fxeHhYe3j40O0220C8/n5Wd7X/v6+Ho1GtYODA/329lZrtVr08FmW6TRNqZ8kiT45OdGYQ9+ECZBQKKvTUuXOILcFR+ZnqophMSioCMDCMCRIURQ5gANQ+AVB4EwmE9FqtYTjOGI6nQrXdakPCHEcL9xTFEX0cEopnec59aMoUjzGXKPRUC8vLxpQGWi73VYmSChyFcRdlVgVXOmv4EJghmyCprKGw6HDsABjMplg7MRx7Pi+Tz8AklI6rutSP01Tp16v19CGYQj1LNxTEARaSkngwjDUs9lM+76vtNaApMIwVGmaKsBEC5BSStVsNgncdDpVUkpS4dHRkWKzHg6H2lThtgA3gVsySYCDOa4DNh6PAYCAeZ7nJEniAlKSJE6WZdQvoGEMeDRO01TwZjo5AMLY8zw1n881WoDL8xyqo9Z13Ryt7/uY1EEQ5ICYJAmOIYg2QFuB8DxGYNlowhvB2aYJpdnQ9vb2AInMMI5jFwqr1+suwKVp6vq+T/LK89wDrDzPsY/msBVzpNYsyxbuyfM8sKMNoAAOW5ZlOYDhV8wDVgkQ8EajkUILU4YS4zhWMOG7uztS31fM90/glswT/uzm5sbBm3l9fXUuLy/FaDRy4MdgmoAGeFJKF+oCODwrwGFjYGh5nOc5wRNC4CcwXqE47TgOzJOg4R0wMCFE5nleDpAMEwChPqgO4KC8KIqwn0y30+lAjWS+DA+K2yYCV1Ic+zUzEGwLDnAAkeEBHJSmlPrX2RXgAM9UXWGqJThAK0yU4GG8DhygzedzUhzg/efgzCi6jakiCARBQKYKHwfzBDiA0lqT2fLGakNrKw7wYLIkN5AugHEf4Gaz2YKvk1KS0gp4pZ9D2mKaqhkkcN2qOd8mxZG/rOLn7GiKoACzRSSFn4PZWoGBFAdQBTxE2IUAwb6N0xH2b9wiSBT+joIEm2cYhgTNVpoZXXc1UX6pVcCV8IqDKB2x8zeA43QECS6i6/Hx8VJKAhU2Gg0BBXJKAlpSSsrlkJLYipvP5zWkIkWAQD5HeVyRolAawikJA0MwsNMRVBzs1346HVl4iFXqW5UAo4QyISLicl6HtggkFFCazWbNToLtiwIQ5gDDTHyRu2EO1QUnwIiccPxQF8o0Owigiri9vdWGSVIRZF9z07iq4ladZ22Oh8V2JWGXXEVutlB2YQ6lF18MJdi6cgtrUHIBDPpshqaqMP+d1YIJ4Svgls7DvrBwsmTi+DPrVz6IC32MUaZBpdi42Od1UA36WxT4pCD+avJdtemS6W2S5Bf2r/xqwuezPyutuo75iYmVw+tQLhW+dh0kXrq1GVZ55u9SXJVrVXpp5ofMNR8szfP8CJQqD/M3wVW5v//tml9wO76afwDoOTRiPwJxLAAAAABJRU5ErkJggg==");
  content: '';
}

.result {
  padding: 100px 0;
}

.result__container {
  position: relative;
  min-height: 1198px;
}

.result__container::after {
  position: absolute;
  top: 200px;
  left: -270px;
  z-index: -1;
  width: 1793px;
  height: 1196px;
  background: url("../img/bg/result_bg.png") no-repeat 65% 149%;
  content: '';
}

.result__item {
  position: absolute;
  padding-left: 40px;
  width: 300px;
}

.result__item::after {
  pointer-events: none;
  position: absolute;
  top: -30px;
  left: -100px;
  content: '';
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.result__item_1 {
  top: 1.7%;
  left: 28.3%;
  width: 330px;
}

.result__item_1::after {
  top: 61px;
  left: -12px;
  width: 166px;
  height: 273px;
  background-image: url("../img/bg/line-dot_1.png");
}

.result__item_2 {
  top: -3.4%;
  left: 57.1%;
  width: 400px;
}

.result__item_2::after {
  top: 61px;
  left: -12px;
  width: 166px;
  height: 301px;
  background-image: url("../img/bg/line-dot_2.png");
}

.result__item_2 .result__value:nth-child(3) .result__value-circle {
  top: 9px;
}

.result__item_3 {
  top: 14.2%;
  left: 3.8%;
}

.result__item_3::after {
  top: 39px;
  left: -14px;
  width: 166px;
  height: 306px;
  background-image: url("../img/bg/line-dot_3.png");
}

.result__item_3 .result__title {
  margin-bottom: 45px;
}

.result__item_4 {
  top: 31.4%;
  left: 23.5%;
}

.result__item_4::after {
  top: 38px;
  left: -68px;
  width: 278px;
  height: 306px;
  background-image: url("../img/bg/line-dot_3.png");
}

.result__item_4 .result__title {
  margin-bottom: 43px;
  padding-left: 12px;
}

.result__item_5 {
  top: 20.5%;
  left: 65.8%;
}

.result__item_5::after {
  top: 38px;
  left: -71px;
  width: 278px;
  height: 306px;
  background-image: url("../img/bg/line-dot_3.png");
}

.result__item_5 .result__title {
  margin-bottom: 44px;
}

.result__item_6 {
  width: 260px;
  top: 50.7%;
  left: 4.3%;
}

.result__item_6::after {
  top: 60px;
  left: -12px;
  width: 166px;
  height: 255px;
  background-image: url("../img/bg/line-dot_4.png");
}

.result__item_6 .result__title {
  margin-bottom: 51px;
}

.result__item_7 {
  top: 61%;
  left: 57.5%;
}

.result__item_7::after {
  top: 40px;
  left: -13px;
  width: 166px;
  height: 302px;
  background-image: url("../img/bg/line-dot_5.png");
}

.result__item_7 .result__title {
  padding-left: 12px;
}

.result__title {
  margin-bottom: 53px;
  font-size: 18px;
}

.result__value {
  display: flex;
  align-items: center;
}

.result__value:not(:last-child) {
  margin-bottom: 22px;
}

.result__value-label {
  margin-bottom: 5px;
  color: #b2b1b1;
  font-size: 13px;
  line-height: 1;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
}

.result__value-block + .result__value-block {
  margin-top: 20px;
}

.result__value-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 61px;
  width: 61px;
  margin-right: 17px;
  border-radius: 50%;
  background: #ffff;
  border: 7px solid #0094f8;
  z-index: 2;
}

.result__value-circle::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: -12px;
  z-index: -1;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #026db5;
  content: '';
}

.result__value-text {
  color: #026db5;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 46px;
  line-height: 45px;
}

.result__value_triple {
  position: relative;
}

.result__value_triple::after {
  position: absolute;
  left: 30px;
  top: 45px;
  background: url("../img/bg/line-triple.png") no-repeat 50% 50%/contain;
  width: 73px;
  height: 166px;
  z-index: 1;
  content: '';
}

.result__value_triple .result__value-circle::after {
  display: none;
}

.result__value_triple .result__value-circle {
  top: 8px;
  margin-right: 26px;
}

.entry {
  min-height: 490px;
  background: #fff;
}

.entry:after {
  content: "";
  display: table;
  clear: both;
}

.entry__pic {
  position: relative;
  float: left;
  width: 50%;
  padding: 70px 20px 200px;
  text-align: center;
  min-height: 490px;
}

.entry__pic-text {
  margin-bottom: 30px;
}

.entry__pic-title {
  margin-bottom: 7px;
  color: #fafdff;
  font-family: "AmazonEmber-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 78px;
  font-weight: 700;
  line-height: 1;
  font-size: 78px;
}

.entry__pic-sub-title {
  color: #fff;
  font-family: "AmazonEmber-Regular", Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
}

.entry__pic-icon {
  position: absolute;
  left: 50%;
  bottom: 39px;
  margin-left: -47px;
}

.entry__content {
  float: left;
  width: 50%;
}

.entry__nav {
  display: flex;
}

.entry__nav-btn {
  flex: 1 1 50%;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 16px;
  height: 47px;
  line-height: 47px;
  background-color: #f2f2f2;
  text-align: center;
  transition: all 0.25s ease-out;
}

.entry__nav-btn:nth-child(1) {
  pointer-events: none;
}

.entry__nav-btn.is-active {
  background: #0094f8;
  color: #fff;
}

.entry__form {
  padding: 30px 35px 35px;
  color: #acacac;
  font-size: 14px;
  line-height: 20px;
}

.entry__form-title {
  margin-bottom: 10px;
  font-size: 18px;
  color: #181818;
}

.entry__form-title-color {
  color: #0094f8;
}

.entry__form-descr {
  max-width: 240px;
  margin: 0 auto 30px;
}

.entry__form-field {
  position: relative;
}

.entry__form-field:nth-child(3) {
  margin-top: -1px;
}

.entry__form-field-icon {
  position: absolute;
  top: 50%;
  z-index: 2;
  margin-top: -9px;
}

.entry__form-field-icon_user {
  left: 14px;
}

.entry__form-field-icon_key {
  left: 16px;
}

.entry__form-input {
  position: relative;
  padding: 0 20px 0 40px;
}

.entry__form-input:focus {
  z-index: 1;
  border-color: #0094f8;
}

.entry__form-input:focus + svg use {
  fill: #0094f8;
}

.entry__form-btn {
  margin-top: 20px;
  width: 100%;
}

.entry__form-options {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}

.entry__form-check {
  flex-shrink: 0;
  white-space: nowrap;
}

.entry__form-link {
  transition: all 0.25s ease-out;
}

.no-touch .entry__form-link:hover {
  color: #0094f8;
}

.entry__form-sign {
  margin: 20px 0 0;
}

.entry__form-sign-title {
  position: relative;
  margin-bottom: 17px;
}

.entry__form-sign-title::after {
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  right: 0;
  height: 1px;
  background-color: #cdcdcd;
  content: '';
}

.entry__form-sign-title span {
  position: relative;
  z-index: 1;
  padding: 0 10px;
  background: #fff;
}

.entry__form-sign-buttons {
  display: flex;
  margin: 0 -2px;
}

.entry__form-sign-btn {
  position: relative;
  height: 32px;
  margin: 0 2px;
  line-height: 32px;
  color: #fff;
  font-size: 14px;
  transition: all 0.25s ease-out;
}

.entry__form-sign-btn_fb {
  flex: 1 0 140px;
  background-color: #4d67a3;
}

.no-touch .entry__form-sign-btn_fb:hover {
  background: #3d5180;
}

.entry__form-sign-btn_fb span {
  padding-left: 30px;
}

.entry__form-sign-btn_fb .entry__form-sign-icon {
  top: 4px;
  left: 2px;
}

.entry__form-sign-btn_fb .entry__form-sign-icon::after {
  right: -3px;
  top: 4px;
}

.entry__form-sign-btn_fb svg {
  width: 28px;
  height: 18px;
}

.entry__form-sign-btn_gp {
  flex: 1 0 144px;
  background-color: #d74936;
}

.no-touch .entry__form-sign-btn_gp:hover {
  background: #b63524;
}

.entry__form-sign-btn_gp span {
  padding-left: 40px;
}

.entry__form-sign-btn_gp .entry__form-sign-icon {
  top: 3px;
  left: 7px;
}

.entry__form-sign-btn_gp .entry__form-sign-icon::after {
  right: -7px;
  top: 5px;
}

.entry__form-sign-btn_gp svg {
  width: 25px;
  height: 16px;
}

.entry__form-sign-icon {
  position: absolute;
  width: 30px;
}

.entry__form-sign-icon::after {
  position: absolute;
  z-index: 1;
  right: 0;
  height: 16px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  content: '';
}

.contacts {
  margin-top: -176px;
  padding-bottom: 100px;
  border-bottom: 1px solid #d8d8d8;
}

.contacts__inner {
  display: flex;
  margin-bottom: 35px;
  padding: 18px 21px;
  height: 326px;
  background: #fff;
  border: 1px solid #d8d8d8;
}

.contacts__map {
  flex: 1 0 auto;
  margin-right: 23px;
}

.contacts__block {
  flex: 0 0 367px;
  padding: 29px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: #fff;
}

.contacts__block-title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 24px;
}

.contacts__block P:not(:last-child) {
  margin-bottom: 20px;
}

.photos {
  display: none;
  max-width: 1078px;
  margin: 0 auto;
}

.photos.slick-initialized {
  display: block;
}

.photos__item {
  position: relative;
  margin: 0 2px;
}

.photos__item-img {
  position: relative;
  display: block;
}

.photos__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.photos__item-img::after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 30, 54, 0.55);
  content: '';
  transition: all 0.25s ease-out;
}

.photos__item-img img {
  margin: 0 auto;
}

.photos__item-img:hover::after {
  opacity: 1;
}

.photos__item-img:hover .icon-plus {
  opacity: 1;
}

.collage {
  padding: 50px 0;
}

.collage img {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.entry-slider {
  display: none;
  max-width: 1138px;
  margin: -172px auto 0;
  padding: 19px;
  background: #f3f3f3;
}

.entry-slider.slick-initialized {
  display: block;
}

.entry-slider__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.entry-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 357px;
  max-width: 1138px;
  margin: -172px auto 0;
  border: 21px solid #f2f2f2;
  background-color: #fff;
}

.entry-title__text {
  font-family: "OpenSans-SemiBold", Arial, sans-serif;
  font-weight: normal;
  font-size: 60px;
  line-height: 62px;
  color: #00203a;
}

.entry-title__text span {
  color: #0897f8;
}

.future {
  padding: 87px 0 90px;
}

.future__container {
  position: relative;
  max-width: 1710px;
  padding: 0 40px;
}

.future__container::after {
  pointer-events: none;
  position: absolute;
  top: 2%;
  right: 9.7%;
  background: url(../img/bg/map_bg.svg) no-repeat 50% 50%/contain;
  height: 64.8%;
  width: 80.7%;
  content: '';
}

.future__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1140px;
  min-height: 1240px;
  margin: 0 auto;
}

.future__item {
  flex: 0 1 auto;
  width: 100%;
}

.future__item_1 {
  padding-top: 185px;
  text-align: left;
}

.future__item_1 .future__item-img {
  margin-left: -36px;
}

.future__item_1 .future__item-tooltip {
  top: -42.4%;
  right: -28.2%;
}

.future__item_2 {
  margin-top: -70px;
  text-align: right;
}

.future__item_2 .future__item-img {
  margin-right: 90px;
}

.future__item_2 .future__item-tooltip {
  top: 23.5%;
  right: -17.1%;
}

.future__item-img {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.future__item-img img:not(:last-of-type) {
  margin-top: 20px;
  margin-right: 16px;
}

.future__item-tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 105;
  text-align: left;
  transition: .3s ease;
}

.future__item-tooltip::after {
  position: absolute;
  top: 0;
  left: 20px;
  height: 1px;
  width: 90%;
  background: #000;
  content: '';
}

.future__item-tooltip-title {
  padding: 14px 0 14px 20px;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 30px;
  color: #000;
}

.future__item-label {
  position: absolute;
  right: -26px;
  bottom: 41%;
  color: #0094f8;
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 23px;
}

.future__row {
  display: flex;
}

.future__row_wrap {
  flex-wrap: wrap;
}

.future__row:not(:last-child) {
  margin-bottom: 50px;
}

.future__block {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  min-height: 245px;
  margin: 0 6px;
  padding-left: 74px;
  box-shadow: 6px 6px 0 rgba(25, 25, 25, 0.22);
  background: #0094f8;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.future__block_1 {
  flex: 0 0 323px;
  background-image: url("../img/bg/future-block_1.png");
}

.future__block_2 {
  flex: 0 0 308px;
  background-image: url("../img/bg/future-block_2.png");
}

.future__block_3 {
  flex: 0 0 314px;
  background-image: url("../img/bg/future-block_3.png");
}

.future__block-text {
  padding: 0 25px;
  font-family: "OpenSans-SemiBold", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.future__block-text span {
  display: block;
  color: #023b61;
}

.future__grid-bg {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 15px;
  right: 68px;
  height: 100%;
  background: url(../img/bg/future-grid.svg) no-repeat 50% 50%/contain;
}
