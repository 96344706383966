/* LIBS */
@import 'libs/vintage-reset-normalize';
@import 'libs/reset-helper';
@import 'libs/slick';
@import 'libs/highcharts';
@import 'libs/jquery.fancybox.min';

/* PARTIALS */
@import 'partials/_mixins';
@import 'partials/_fonts';
@import 'partials/_helpers';
@import 'partials/_svg';
@import 'partials/_keyframes';

/* COMPONENTS */
@import 'components/common';
@import 'components/header';
@import 'components/footer';
@import 'components/popup';

/* PAGES */
@import 'pages/home';
