//fonts
@mixin font($alias, $name) {
	@font-face {
		font-family: $alias;
		src: url("../fonts/" + $name + ".eot");
		src: url("../fonts/" + $name + ".eot?#iefix") format("embedded-opentype"), url("../fonts/" + $name + ".woff") format("woff"), url("../fonts/" + $name + ".woff2") format("woff2"), url("../fonts/" + $name + ".ttf") format("truetype");
		font-weight: normal;
		font-style: normal; } }

@include font('OpenSans-Regular', 'OpenSans-Regular');
@include font('OpenSans-SemiBold', 'OpenSans-SemiBold');
@include font('OpenSans-Bold', 'OpenSans-Bold');
@include font('AmazonEmber-Regular', 'AmazonEmber-Regular');
@include font('AmazonEmber-Bold', 'AmazonEmber-Bold');

@mixin f($name, $font-size: null, $line-height: null, $letter-spacing: null) {
	$font-family: null;
	@if $name == "os-400" {
		$font-family: "OpenSans-Regular"; }
	@if $name == "os-600" {
		$font-family: "OpenSans-SemiBold"; }
	@if $name == "os-700" {
		$font-family: "OpenSans-Bold"; }
	@if $name == "ae-400" {
		$font-family: "AmazonEmber-Regular"; }
	@if $name == "ae-700" {
		$font-family: "AmazonEmber-Bold"; }
	font-family: $font-family, Arial, sans-serif;
	@if $font-family != null {
		font-weight: normal; }
	@if $font-size != null {
		font-size: $font-size * 1px; }
	@if $line-height != null {
		line-height: $line-height * 1px; }
	@if $letter-spacing != null {
		letter-spacing: ($letter-spacing / 1000) * 1em; } }
