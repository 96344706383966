.footer {
  position: relative;
  background-color: #2a363d;
  color: #9f9f9f;
  &__container {
    padding: 33px 15px 40px; }
  &__logo {
    margin-bottom: 25px;
    .logo__icon {
      margin-right: 10px; }
    p {
      @include f('os-600', 16); }
    p, span {
      color: #fff; } }
  &__row {
    position: relative;
    margin: 0 -14px;
    @include clr; }
  &__col {
    float: left;
    width: calc(33.33333% - 28px);
    margin: 0 14px;
    border-right: 1px solid #3b4b55;
    &::before, &::after {
      position: absolute;
      top: 4px;
      z-index: 1;
      width: 1px;
      height: 37px;
      background: #fff;
      content: ''; }
    &::before {
      top: 0; }
    &::after {
      top: auto;
      bottom: 0; }
    &:nth-child(1)::before,
    &:nth-child(1)::after {
      left: 374px; }
    &:nth-child(2)::before,
    &:nth-child(2)::after {
      left: 763px; }
    &:nth-child(3) {
      border-right: 0;
      background: url("../img/bg/pointer.svg") no-repeat 10% 25%;
      &::after, &::before {
        display: none; } } }
  &__article {
    margin-bottom: 22px; }
  &__title {
    margin-bottom: 25px; }
  &__bottom {
    box-shadow: 0 -1px 0 #3b4b55;
    background-color: #252f35;
    &-container {
      display: flex;
      justify-content: space-between;
      padding: 22px 15px;
      line-height: 1; } }
  &__list {
    a {
      @include trs;
      .no-touch &:hover {
        color: $blue; } } }
  &__subscr {
    margin-top: 20px; }
  &__lang {
    .lang__link::after {
      border-color: #fff transparent transparent transparent; }
    .lang__list-items {
      top: auto;
      bottom: 42px;
      border-color: #3b4b55;
      background: #2e393f;
      a {
        .no-touch &:hover {
          background: $blue; } } }
    .lang__list-text {
      color: #fff; } } }
.subscr {
  &__title {
    margin-bottom: 30px; }
  &__field {
    display: flex; }
  &__input {
    height: 37px;
    background-color: #ffffff;
    border: 0; }
  &__submit {
    height: 37px;
    width: 117px;
    flex: 0 0 117px;
    padding: 0 10px; } }
