.icon-circle {
  width: 1em;
  height: 1em;
  fill: #9fbbd7;
  stroke: #e8f4fe;
}
.icon-screen-tooltip-bg {
  width: 2.26em;
  height: 1em;
  fill: #dee2ef;
  stroke: initial;
}
.icon-squares {
  width: 1.09em;
  height: 1em;
  fill: #000414;
  stroke: initial;
}
