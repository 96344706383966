button {
	background-color: transparent;
	border: 0;
	outline: 0;
	padding: 0; }

a:hover, a:focus {
	text-decoration: none; }

a {
	color: inherit;
	text-decoration: none; }

table {
	vertical-align: top; }

img {
	max-width: 100%;
	vertical-align: top; }

* {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	color: inherit;
	font-weight: inherit; }

::-ms-clear,
::-ms-reveal {
	display: none;
	width: 0;
	height: 0; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0; }

//video::-webkit-media-controls-start-playback-button
//	display:none !important1
