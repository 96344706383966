.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: 305;
  overflow: auto;
  outline: 0;
  text-align: center;
  font-size: 0;
  transform: translate3d(0, 0, 0);
  background-color: rgba(#000, .8);
  transition: opacity .5s ease, transform .5s ease, visibility .5s;
  will-change: opacity, transform;
  $parent: &;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%; }
  &.opened {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible; }
  &__close {
    position: absolute;
    top: 0;
    right: -31px;
    width: 46px;
    height: 47px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #064b7a;
    @include trs;
    &:hover {
      background: lighten(#064b7a, 15); }
    &-icon {
      position: relative;
      width: 20px;
      height: 20px;
      .icon-plus {
        position: static;
        opacity: 1; } } }

  &__container {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 748px;
    padding: 0 15px;
    position: relative;
    margin: 30px auto;
    z-index: 102;
    transition: opacity .5s ease, transform .5s ease;
    transform: translate3d(0, -100px, 0);
    will-change: transform, opacity;
    @include xs {
      padding: 30px 15px; }
    &_top {
      vertical-align: top;
      max-width: 740px;
      margin: 30px auto; } } }

body.popup-opened {
  overflow: hidden; }

.popup.opened .popup__container {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.popup.opened[data-popup-id='login-popup'] {
  background: rgba(#0e1c2a, .87);
  z-index: 350; }
