@keyframes scaleAnim {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(.9); }
  100% {
    transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(0); }

  50% {
    transform: scale(1); }

  100% {
    transform: scale(1);
    opacity: 0; } }
